<template>
    <div>
        <nav
            class="flex flex-col h-screen  p-4 fixed top-0 left-0 w-2/3 shadow-xl bg-white z-50 transition-transform transform  ease-in-out duration-300 overflow-scroll"
            :class="[drawerStore.isDrawerOpen ? '-translate-x-0' : '-translate-x-full']"
        >
          <div class="flex flex-row items-center justify-center w-full mt-4 mb-8">
          <NuxtImg
              src="/images/main/logo.png"
              alt=""
              class="h-16 w-auto"
          />
          </div>
            <div class="flex flex-col items-center justify-center p-2 mb-4"
                 v-if="isLoggedIn"
            @click="navigateTo({name:'dashboard-profile'})"
            >
                <NuxtImg
                    :src="authManager.authUserAvatarUrl.value"
                    class="border-2 rounded-full w-20 h-20 border-a-neutral-dim mx-1"
                    width="35"
                    height="35"
                    alt=""
                />
                <span class="font-semibold text-xs">{{ authManager.authUserFullName.value }}</span>
            </div>
            <BaseButton
                @click.native="gotoHome"
                primary-color-class="a-primary"
                margin-classes="mb-4"
            >
                <Icon
                    name="mdi:home"
                    class="px-1 text-3xl"
                />
                Home
            </BaseButton>
            <BaseButton
                v-if="isLoggedIn"
                @click.native="gotoDashboardMain"
                primary-color-class="a-primary"
                margin-classes="mb-4"
            >
                <Icon
                    name="mdi:desktop-mac-dashboard"
                    class="px-1 text-3xl"
                />
                Dashboard
            </BaseButton>
            <BaseButton
                v-if="isLoggedIn"
                primary-color-class="a-neutral-dim"
                margin-classes="mt-8"
                @click="logoutUser()"
            >
                <Icon
                    name="solar:logout-line-duotone"
                    class="px-2"
                /><span>Logout</span>
            </BaseButton>

          <BaseButton
              v-if="!isLoggedIn"
              @click.native="gotoLogin"
              primary-color-class="a-primary"
              margin-classes="mb-4"
          >
            <Icon
                name="material-symbols:login-rounded"
                class="px-1 text-3xl"
            />
            Login
          </BaseButton>

          <BaseButton
              v-if="!isLoggedIn"
              @click.native="gotoRegister"
              primary-color-class="a-primary"
              margin-classes="mb-4"
          >
            <Icon
                name="material-symbols:app-registration-outline-sharp"
                class="px-1 text-3xl"
            />
            Register
          </BaseButton>
        </nav>
        <div
            v-if="drawerStore.isDrawerOpen"
            @click="drawerStore.closeDrawer()"
            class="fixed inset-0 z-40 bg-black opacity-50"
        ></div>
    </div>
</template>
  
<script setup>
import {navigateTo} from "#app";

const authManager = useAuthManager();
const drawerStore = useDrawer();

function gotoLogin(){
  navigateTo({
    name: 'login'
  })
}

function gotoRegister(){
  navigateTo({
    name: 'register'
  })
}

function gotoDashboardMain() {
    navigateTo({
        name: "dashboard",
    });
}

function gotoHome() {
    navigateTo({
        name: "index",
    });
}

onBeforeUnmount(()=>{
  useDrawer().closeDrawer();
})

const isLoggedIn = computed(()=>{
  return authManager.isLoggedIn.value;
})


async function logoutUser() {
    await authManager.logout('/login')
}

</script>
  
<style scoped>a.router-link-exact-active {
    font-weight: 500;
    /* background-color: #e7ebeb; */
    @apply bg-a-secondary-lightest;
    @apply border-l-4;
    @apply border-a-secondary;
    @apply text-a-secondary;
}</style>
  